import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

// Featured Slider
var feature_slider = new Swiper('.feature-slider-container', {
    direction: 'horizontal',
    autoHeight: false,
    observer: true,
    observeParents: true,
    loop: false,
    parallax: true,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    autoplay: {
      delay: 6000,
    },
    pagination: {
      el: '.feature-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Testimonial Slider
var testimonial_content_slider = new Swiper('.testimonial-slider-container', {
    direction: 'horizontal',
    autoHeight: false,
    // slidesPerView: 1,
    // spaceBetween: 10,
    // centeredSlides: true,
    loop: true,
    //watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
});